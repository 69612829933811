const validations = {
  email: {
    required: 'Campo requerido',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Correo electrónico inválido',
    },
  },

  password: {
    required: 'Debes ingresar una contraseña',
    minLength: {
      value: 8,
      message: 'La contraseña debe incluir mínimo 8 caracteres',
    },
  },

  emailFriend: {
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Correo electrónico inválido',
    },
  },

  required: {
    required: 'Campo requerido',
  },
};

export default validations;
