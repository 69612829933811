import React from 'react';
import s from './index.module.scss';

const input = ({
  name,
  className,
  type,
  referencia,
  placeholder,
  err,
  label,
  value,
  onChange,
  styles,
  width,
}) => {
  return (
    <div className={s.container} style={{ ...styles, width: width || 'auto' }}>
      {label && (
        <div className={s.boxLabel}>
          <label className={s.label} htmlFor={name}>
            {label}
          </label>
        </div>
      )}

      <input
        id={name}
        name={name}
        ref={referencia}
        className={s[className]}
        type={type ? type : 'text'}
        placeholder={placeholder || ''}
        value={value}
        onChange={onChange}
      />

      {err && <div className={s.error}>{err.message}</div>}
    </div>
  );
};

export default input;
